import { IApiResponse } from '@/models/api';
import { ISendMessageRequest } from '@/models/cs/api/chat-message';
import {
  IAnswerSurveyRequest,
  ICheckPlayerInChatResponse,
  IPlayerRoomNarrowRequest,
  IRoomHistoryModel,
  ICreateRoomRequest
} from '@/models/cs/api/room';
import {
  IGetSurveyRequest,
  IGetSurveyResponse,
  IOfflineSurveyRecord,
} from '@/models/cs/api/survey';
import { HttpHandler } from '@gpp/utils';

// TODO 要再確認
const basePath = '/onlinechat';

class ChatApiHandler {
  private handler: HttpHandler;

  constructor() {
    this.handler = new HttpHandler(window.location.origin);
  }

  // common api
  async removeToken(): Promise<boolean> {
    const res: IApiResponse<boolean> = await this.handler.post(`${basePath}/api/common/remove-token`);
    return res.data;
  }

  // room api
  async checkAnyUserOnline(): Promise<boolean> {
    const res: IApiResponse<boolean> = await this.handler.get(`${basePath}/api/room/cs-status`);
    return res.data;
  }

  async getInitialQueueNumber(): Promise<number> {
    const res: IApiResponse<number> = await this.handler.get(`${basePath}/api/room/queue-number`);
    return res.data;
  }

  async createRoomAndSendSurvey(request: ICreateRoomRequest): Promise<IApiResponse<string>> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/room`, request);
    return res;
  }

  // TODO 要接 statusCode
  async checkPlayerInChat(): Promise<IApiResponse<ICheckPlayerInChatResponse>> {
    const res: IApiResponse<ICheckPlayerInChatResponse> = await this.handler.get(`${basePath}/api/room/player/in-chat`);
    return res;
  }

  async closeRoom(): Promise<string> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/room/player/close`);
    return res.data;
  }

  async sendMessage(request: ISendMessageRequest): Promise<string> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/room/send`, request);
    return res.data;
  }

  async getRoomHistory(): Promise<IRoomHistoryModel[]> {
    const res: IApiResponse<IRoomHistoryModel[]> = await this.handler.get(`${basePath}/api/room/in-process`);
    return res.data;
  }

  async clearUnRead(messageId: number): Promise<string> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/room/read/${messageId}`);
    return res.data;
  }

  async setRoomDisplayStatus(request: IPlayerRoomNarrowRequest): Promise<string> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/room/narrow`, request);
    return res.data;
  }

  async chatTokenValidation(): Promise<boolean> {
    const res: IApiResponse<boolean> = await this.handler.get(`${basePath}/api/room/token-validation`);
    return res.data;
  }

  // survey api
  async getSurvey(request: IGetSurveyRequest): Promise<IGetSurveyResponse> {
    const res: IApiResponse<IGetSurveyResponse> = await this.handler.get(`${basePath}/api/survey/skill-survey`, request);
    return res.data;
  }

  async submitExitSurvey(request: IAnswerSurveyRequest): Promise<IApiResponse<string>> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/survey/answer`, request);
    return res;
  }

  async submitOfflineSurvey(request: IOfflineSurveyRecord): Promise<string> {
    const res: IApiResponse<string> = await this.handler.post(`${basePath}/api/survey/create-offline`, request);
    return res.data;
  }
}
export const chatApiHandler = new ChatApiHandler();

export default chatApiHandler;
