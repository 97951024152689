import { HubConnectionState, RxSignalR } from '@gpp/utils';
import { Observable, Subject } from 'rxjs';
import { concatMap, debounceTime } from 'rxjs/operators';

class DebounceTrigger<P = void, R = void> {
  private readonly sender: Subject<P> = new Subject<P>();
  private receiver?: Observable<R | 'no fire'>;
  constructor(private readonly delay: number, private readonly trigger: (payload: P) => Promise<R>) {}
  build(): DebounceTrigger<P, R> {
    const self = this;
    this.receiver = this.sender.pipe(
      debounceTime(this.delay),
      concatMap(async p => {
        if (p === undefined) {
          return 'no fire';
        }
        return await self.trigger(p);
      }),
    );
    return this;
  }
  start(): { stop: () => void } {
    const subscription = this.receiver?.subscribe();
    return {
      stop: () => subscription?.unsubscribe(),
    };
  }
  fire(payload: P): void {
    this.sender.next(payload);
  }
}

export class SocketFactory {
  private instance?: RxSignalR;
  constructor(private hubUrl: string, private retryDelays?: number[]) {}
  get ws(): RxSignalR | undefined {
    return this.instance;
  }
  build(token: string): RxSignalR {
    if (this.instance) {
      this.instance.stateSubject.value === HubConnectionState.Connected && this.instance.stop();
    }
    this.instance = new RxSignalR({
      hubUrl: this.hubUrl,
      token,
      retryDelays: this.retryDelays,
    });
    return this.instance;
  }
  createDebounceSender<P = void, R = void>(delay: number, trigger: (payload: P) => Promise<R>): DebounceTrigger<P, R> {
    return new DebounceTrigger<P, R>(delay, trigger);
  }
}
