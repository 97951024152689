import { createApp } from 'vue';
import { createStore, stateSymbol } from '@/store/state';
import { createI18n, i18nSymbol } from '@/services/i18n';
import MessageCenter from '@/services/message-center';
import { touch } from '@/services/directive';
import { RxSignalR } from '@gpp/utils';
import { IMessageDialog, MessageType } from '@/models/message-center';
import App from '@/App.vue';
import router from './router';
import Cookies from 'js-cookie';
import { SocketFactory } from '@/services/SocketFactory';

const store = createStore();
const i18n = createI18n();
const messageCenter = new MessageCenter(i18n);
const chatSystem = createApp(App);

const installMessageCenter = () => {
  chatSystem.config.globalProperties.$message = messageCenter.$message;
  ['pop', 'prompt'].forEach((type) => {
    chatSystem.config.globalProperties.$message[type] = (msg: string | number | object | symbol) => messageCenter.$message(msg, type as MessageType);
  });
  ['confirm', 'warn', 'error'].forEach((type) => {
    chatSystem.config.globalProperties[`$${type}`] = (v: string | IMessageDialog) => {
      if (typeof v === 'string') {
        return messageCenter.popDialog({ title: v, type });
      }
      const config = { ...v };
      config.type = type;
      return messageCenter.popDialog(config);
    };
  });
};

const initChatSystem = async () => {
  chatSystem.config.globalProperties.$t = (key: string, variable?: { [key: string]: string }) => i18n.$t(key, variable);
  chatSystem.config.globalProperties.$message = messageCenter.$message;
  chatSystem.config.globalProperties.$socketFactory = new SocketFactory('/chat-ws');
  const culture = Cookies.get('culture');
  if (culture) {
    store.mutation.setCulture(culture);
    await i18n.setLocale(store.state.culture);
    document.title = i18n.$t('Common_Page_Title');
  }
  // 分派 csOnReady 事件
  window.dispatchEvent(new CustomEvent('csOnReady'));
  installMessageCenter();
  chatSystem.provide(stateSymbol, store)
    .provide(i18nSymbol, i18n)
    .directive('touch', touch)
    .use(router)
    .mount('#chat-system');
};

initChatSystem().then();
